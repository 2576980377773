/* lato-100normal - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Lato Thin '),
    local('Lato-Thin'),
    url(/static/media/lato-latin-100.f8028e61.woff2) format('woff2'), 
    url(/static/media/lato-latin-100.752c2022.woff) format('woff'); /* Modern Browsers */
}

/* lato-100italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Lato Thin italic'),
    local('Lato-Thinitalic'),
    url(/static/media/lato-latin-100italic.ef884f11.woff2) format('woff2'), 
    url(/static/media/lato-latin-100italic.6ea83803.woff) format('woff'); /* Modern Browsers */
}

/* lato-300normal - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Lato Light '),
    local('Lato-Light'),
    url(/static/media/lato-latin-300.bbbd26ae.woff2) format('woff2'), 
    url(/static/media/lato-latin-300.ba2452bd.woff) format('woff'); /* Modern Browsers */
}

/* lato-300italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Lato Light italic'),
    local('Lato-Lightitalic'),
    url(/static/media/lato-latin-300italic.7d718d72.woff2) format('woff2'), 
    url(/static/media/lato-latin-300italic.06eb177e.woff) format('woff'); /* Modern Browsers */
}

/* lato-400normal - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Lato Regular '),
    local('Lato-Regular'),
    url(/static/media/lato-latin-400.f1a4a058.woff2) format('woff2'), 
    url(/static/media/lato-latin-400.62fb51e9.woff) format('woff'); /* Modern Browsers */
}

/* lato-400italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Lato Regular italic'),
    local('Lato-Regularitalic'),
    url(/static/media/lato-latin-400italic.b45e52f3.woff2) format('woff2'), 
    url(/static/media/lato-latin-400italic.c4082958.woff) format('woff'); /* Modern Browsers */
}

/* lato-700normal - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Lato Bold '),
    local('Lato-Bold'),
    url(/static/media/lato-latin-700.7fbbfd16.woff2) format('woff2'), 
    url(/static/media/lato-latin-700.96759e32.woff) format('woff'); /* Modern Browsers */
}

/* lato-700italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Lato Bold italic'),
    local('Lato-Bolditalic'),
    url(/static/media/lato-latin-700italic.c66215e0.woff2) format('woff2'), 
    url(/static/media/lato-latin-700italic.0a06ba1b.woff) format('woff'); /* Modern Browsers */
}

/* lato-900normal - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Lato Black '),
    local('Lato-Black'),
    url(/static/media/lato-latin-900.f2de2c6e.woff2) format('woff2'), 
    url(/static/media/lato-latin-900.d79dd066.woff) format('woff'); /* Modern Browsers */
}

/* lato-900italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Lato Black italic'),
    local('Lato-Blackitalic'),
    url(/static/media/lato-latin-900italic.fd67cf72.woff2) format('woff2'), 
    url(/static/media/lato-latin-900italic.e792d6c6.woff) format('woff'); /* Modern Browsers */
}


